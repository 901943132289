@import '../../styles/app';

.root {
    z-index: 100;
    background: var(--navbar-bg);
    box-shadow: var(--navbar-shadow);
    height: $navbar-height;
    transition: background-color 0.2s ease, margin 0.2s ease;

    &.navbarFloatingType {
        margin: $spacer $content-padding 0;
        border-radius: $border-radius;

        @media (max-width: breakpoint-max(sm)) {
            margin-left: $content-padding-sm;
            margin-right: $content-padding-sm;
        }
    }
    a {
        color: #495057;
    }
    @include media-breakpoint-down(sm) {
        padding: 7px 0;
    }

    :global {
        .input-group {
            overflow: hidden;
        }

        .input-group-prepend {
            background-color: #fff;
            transition: background-color ease-in-out 0.15s;
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
        }

        .focus .input-group-prepend {
            background: #f8f9fa;
        }
    }
}

.logo {
    font-size: 16px;
}

.navbarForm {
    padding: 6px 0 6px 1rem;
    margin-left: 10px;
    display: inline-block;
    top: 2px;
    width: auto;

    .inputAddon {
        position: relative;
        display: inline;
        border: none;
        background-color: #fff;
        transition: background-color ease-in-out 0.15s;
    }

    input {
        border: none;
        padding: 0.6rem 0.85rem 0.6rem 0;
        display: inline !important;
        width: 250px !important;
        top: 2px;
    }
}

.chatNotification {
    position: absolute;
    right: 35px;
    top: 50px;
    z-index: 20;
    margin-top: 3px;
    padding: 5px 0;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    &::before {
        content: ' ';
        position: absolute;
        top: 0;
        right: 18px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $gray-800;
    }

    .chatNotificationInner {
        min-width: 120px;
        padding: 8px;
        font-size: 12px;
        border-radius: 0.25rem;
        text-decoration: none;
        background-color: $gray-800;
        color: #fff;
    }

    .text {
        margin-top: 5px;
        margin-bottom: 0;
        color: $gray-600;
    }

    .title {
        margin: 0;
        font-weight: 600;
        line-height: 28px;
        font-size: 0.875rem;

        span {
            margin-right: 7px;
        }
    }
}

.navbarBrand {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 1.25rem;
    pointer-events: none;

    i {
        font-size: 10px;
    }
}

.notificationsMenu {
    :global .dropdown-menu {
        left: auto !important;
        right: 0 !important;
        top: $navbar-height !important;
    }
}

.notificationsWrapper {
    @include media-breakpoint-down(sm) {
        width: 75vw;
    }
    width: min-content;
    z-index: -10 !important;
}

.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 40px;
    width: 40px;
    background: $warning;
    font-weight: 600;
    font-size: 18px;
}

.chatNotificationInit {
    opacity: 1;
    pointer-events: initial;
}
@media (max-width: 1020px) {
    .headerSearchInput {
        margin-left: 0 !important;
    }
}

@media (max-width: 950px) {
    .adminEmail {
        display: none;
    }
    .headerSearchInput {
        width: 180px;
    }
}

.headerSvgFlipColor {
    // display: flex;
    color: var(--navbar-icon-bg);
    :global {
        .bg-primary {
            background-color: var(--navbar-icon-bg) !important;
        }
    }
}

.wifiIcon {
    width: 20px;
}

.wifiSlashed {
    position: relative;
    width: 20px;
    height: 20px;
    i {
        position: absolute;
        top: -9px;
        left: 0;
        &.doubleSlash {
            color: #fff;
            top: -8px;
        }
    }
}
