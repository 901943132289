@import '../../../styles/app';

ul.pagination {
  li {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    a {
      outline: none!important;
    }
  }
  li.active {
    // background-color: #1A86D0;
    font-weight: bold;
    a {
      text-decoration: none;
      border: none;
      outline: none!important;
    }
  }
}

.messageList {
  margin-top: 10px;
  li {
    border-top: 1px solid #eee;
  }
}
.agentsLabel {
  margin-top: 10px;
}
.agentsRow {
  padding-bottom: 10px;
}
.expandIcon {
  margin-right: -16px;
  margin-left: 8px;
  cursor: pointer;
  &:hover {
    i {
      color: #00000080;
    }
  }
}
.attachmentsBlock {
  margin-top: 10px;
  margin-right: 10px;
  .attachmentImg {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
  }
}
.messageText {
  white-space: pre-line;

  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.labels {
  padding-top: 5px;
  padding-bottom: 5px;
  i {
    cursor: pointer;
  }
  span {
    font-size: 12px!important;
  }
}

.roundButton {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ededed;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticketData {
  overflow-wrap: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}