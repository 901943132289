@import '../../../../styles/app';

.avatarsRow {
    display: flex;
    align-items: center;
    padding-right: 10px;
    margin-bottom: 0;

    li {
        display: flex;
        justify-content: center;
    }
}

.addButton {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #ededed;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.opsList {
    background-color: #FFF;

    li {
        cursor: pointer;
    }
}
