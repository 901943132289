@import '../../../styles/app';

.tierCard {
    width: 33%;
    flex: 1;
    padding: 10px;
    padding-top: 20px;
    border-radius: 6px;
    border: 1px solid transparent;
    position: relative;
    cursor: pointer;
    
    &.active {
        border-color: $purple
    }

    .title {
        height: 59px;
        font-weight: bold;
        align-items: center;
        justify-content: center;
    }

    .infoIcon {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }
}