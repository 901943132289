@import '../../../../styles/app';

.chatMessage {
    
    position: relative;
    padding-left: 60px;
    margin-bottom: $spacer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .messageBody {
        border-radius: $border-radius;
        padding: $spacer * 0.5 $spacer;
        line-height: 2;
        margin-bottom: $spacer * 0.5;
        background: $white;
        display: inline-block !important;

        textarea {
            border: none;
            outline: none;
        }

        &.messageAttachment {
            max-width: 410px;
            padding: 0;
            overflow: hidden;
        }

        img {
            max-width: 100%;
        }

        &.action {
            font-size: 12px;
            background-color: transparent;
            width: 100%;
            text-align: center;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    .actionTime {
        margin-right: auto;
        margin-left: auto;
    }
    .quickReplyHolder {
        display: flex;
        max-width: 320px;
        flex-wrap: wrap;
    }
    .quickReply {
        font-weight: 400;
        text-align: center;
        flex-basis: 38%;
        background-image: none;
        white-space: nowrap;
        line-height: 1.42857143;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 0;
        color: #3a6d99;
        display: block;
        width: 100%;
        background: #f0f4f7;
        font-size: 14px;
        margin: 0 5px;
        margin-bottom: 10px;
        padding: 3px 6px;
    }

    .messageAvatar {
        position: absolute;
        top: 0;
        left: 10px;
    }

    .username {
        
        font-size: 12px;
    }
    

    &.inEdit {
        .messageBody {
            background-color: #FFF !important;
        }
    }

    &.owner {
        padding-left: 0;
        padding-right: 60px;
        align-items: flex-end;

        .messageBody {
            background: lighten($purple, 15%);
            color: $white;

            a {
                color: $white;
                font-weight: 700;
            }
        }

        .messageAvatar {
            left: unset;
            right: 10px;
        }

    }

    &.standart {
        position: relative;
    }
    &.offline {
        .messageBody {
            &::before {
                font-family: 'Line Awesome Free';
                font-weight: 900;
                content: '\f1da';
                height: 100%;
                font-size: 25px;
                position: absolute;
                top: 0;
                left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: yellow;
                padding: 5px;
                border-radius: 4px;
            }
        }
    }
    &.routine {
        .messageBody {
            &::before {
                font-family: 'Line Awesome Free';
                font-weight: 900;
                content: '\f544';
                height: 100%;
                font-size: 25px;
                position: absolute;
                top: 0;
                left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: orange;
                padding: 5px;
                border-radius: 4px;
            }
        }
    }
    &.action {
        text-align: center;
        padding-left: 0;
        justify-content: center;
        align-items: center;
    }
}

:global(.messageBodyWrapper) {
    :global(.phone-tag) {
      background-color: yellow;
      color: black;
      border-radius: 4px;
      padding: 4px 8px;
    }
    &:hover {
        :global(.phone-tag) {
            position: relative;

            :global(.message-popover) {
                display: block !important;
                position: absolute;

                top: -40px;
                right: 0;
                margin: 0 auto;

                text-align: center;

                // min-width: 200px;

                background-color: #EFEFEF;
                border: 1px solid #dedede;
                border-radius: 4px;
                padding: 6px;

                button {
                    white-space: nowrap;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border: 0.625em solid transparent;
                    border-top-color: #EFEFEF;
                    border-bottom: 0;
                    margin-left: -0.625em;
                    margin-bottom: -0.625em;
                }
            }
        }
    }
}
