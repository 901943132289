@import '../../../../styles/app';

.dropZoneWrapper {
  position: relative;
  outline: none;
  display: flex;
  flex: 1 1;
  flex-direction: column !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.dropZone {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}