.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 8px;
    border-radius: 2px;
    box-shadow: inset 0px 1px 6px -3px #ABABAB;
    background: #fefefe;
    max-width: 100%;
  }
  
  .editor :global(.public-DraftEditor-content) {
    min-height: 40px;
  }