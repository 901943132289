#root {
  --sidebar-item-active: #F6F6E9;
  --important-notification-color: #f55d5d;
  --badge-item: #ffffff;
  --update-notification-color: #FD5F00;
  .sing-dashboard,
  .dashboard-light {
    --sidebar-icon-bg: #4A5056;
    --sidebar-item-active: #6C757D;
    --logo-color: #6C757D;
    --sidebar-bg-color: #ffffff;
    --sidebar-color: #6c757d;
    --sidebar-action-bg: #f9fafe;
    --sidebar-item-active-color: "#FFFFFF";
    --sidebar-nav-title-color: #0e1113;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #FFFFFF;

    --widget-shadow-color: 153, 187, 221;

    --navbar-shadow: none;
  }

  .dashboard-dark {
    --logo-color: $white;

    --sidebar-icon-bg: #5CB0FF;
    --sidebar-bg-color: #002B49;
    --sidebar-color: #F6F6E9;
    --sidebar-action-bg: #042338;
    --sidebar-item-active: #F6F6E9;
    --sidebar-nav-title-color: #dedede;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #14395C;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-sea {
    --sidebar-icon-bg: #5CB0FF;
    --logo-color: $white;
    --sidebar-item-active: #F6F6E9;
    --sidebar-bg-color: #004472;
    --sidebar-color: #F6F6E9;
    --sidebar-action-bg: #00395F;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #14395C;

    --sidebar-nav-title-color: #dedede;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-sky {
    --logo-color: $white;
    --sidebar-icon-bg: #0D2B47;
    --sidebar-item-active: #14191D;
    --sidebar-bg-color: #E9EBEF;
    --sidebar-color: #14191D;
    --sidebar-action-bg: #DBDEE4;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #D5E6F3;

    --sidebar-nav-title-color: #004472;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-wave {
    --sidebar-icon-bg: #0D2B47;
    --logo-color: $white;
    --sidebar-item-active: #14191D;
    --sidebar-bg-color: #D1E7F5;
    --sidebar-color: #14191D;
    --sidebar-action-bg: #BEDDF2;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #D5E6F3;

    --sidebar-nav-title-color: #004472;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-rain {
    --logo-color: $white;
    --sidebar-item-active: #14191D;
    --sidebar-bg-color: #CCDDE9;
    --sidebar-color: #14191D;
    --sidebar-action-bg: #BACEDC;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #D5E6F3;

    --sidebar-nav-title-color: #004472;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-middle {
    --logo-color: $white;
    --sidebar-item-active: #14191D;
    --sidebar-bg-color: #D7DFE6;
    --sidebar-color: #14191D;
    --sidebar-action-bg: #BDC9D4;

    --sidebar-section: #5287B9;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #53AA8D;

    --sidebar-badge-color: #D5E6F3;

    --sidebar-nav-title-color: #004472;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-black {
    --sidebar-icon-bg: #5CB0FF;
    --logo-color: $white;
    --sidebar-item-active: #F6F6E9;
    --sidebar-bg-color: #13191D;
    --sidebar-color: #F6F6E9;
    --sidebar-action-bg: #080D0F;
    --sidebar-nav-title-color: #F6F6E9;

    --sidebar-section: #5287B9;
    --sidebar-sales: #3E86CA;
    --sideba-responsibility: #EC692C;

    --sidebar-hightlight: #53AA8D;
    --sidebar-hightlight-two:  #FF7A3C;

    --sidebar-ui: #3E86CA;
    --sidebar-core:  #EC692C;
    --sidebar-form: #21578E;

    --sidebar-badge-color: #0D2B47;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }

  .dashboard-salat {
    --sidebar-icon-bg: #0D2B47;
    --logo-color: $white;
    --sidebar-item-active: #F6F6E9;
    --important-notification-color: #fd5f00;
    --sidebar-bg-color: #20AE8C;
    --sidebar-color: #F6F6E9;
    --sidebar-action-bg: #119475;
    --update-notification-color: #004472;
    --sidebar-nav-title-color: #dedede;

    --sidebar-section: #1D5442;
    --sidebar-sales: #21578E;
    --sideba-responsibility: #0D2B47;

    --sidebar-hightlight: #8BFFE3;
    --sidebar-hightlight-two:  #FFC0A5;

    --sidebar-ui: #8BFFE3;
    --sidebar-core:  #FFC0A5;
    --sidebar-form: #0D2B47;

    --sidebar-badge-color: #FFFFFF;

    --widget-shadow-color: 115, 162, 208;

    --navbar-shadow: 0 15px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }
  .header-FFFFFF {
    --input-bg: #F9FAFE;
    --input-color: #4A5056;
    --navbar-bg: #FFFFFF;
    --navbar-icon-bg: #4A5056;
  }
  .header-002B49 {
    --input-bg: #14395C;
    --input-color: #FFFFFF;
    --navbar-bg: #002B49;
    --navbar-icon-bg: #5CB0FF;
  }
  .header-004472 {
    --input-bg: #14395C;
    --input-color: #FFFFFF;
    --navbar-bg: #004472;
    --navbar-icon-bg: #5CB0FF;
  }
  .header-E9EBEF {
    --input-bg: #DCDEE3;
    --input-color: #14191D;
    --navbar-bg: #E9EBEF;
    --navbar-icon-bg: #0D2B47;
  }
  .header-D1E7F6 {
    --input-bg: #C4DCF0;
    --input-color: #14191D;
    --navbar-bg: #D1E7F6;
    --navbar-icon-bg: #0D2B47;
  }
  .header-CCDDE9 {
    --input-bg: #BECDDA;
    --input-color: #14191D;
    --navbar-bg: #CCDDE9;
    --navbar-icon-bg: #0D2B47;
  }
  .header-D7DFE6 {
    --input-bg: #C4DCF0;
    --input-color: #4A5056;
    --navbar-bg: #D7DFE6;
    --navbar-icon-bg: #0D2B47;
  }
  .header-13191D {
    --input-bg: #090D0F;
    --input-color: #FFFFFF;
    --navbar-bg: #13191D;
    --navbar-icon-bg: #5CB0FF;
  }
  .header-21AE8C {
    --input-bg: #439277;
    --input-color: #FFFFFF;
    --navbar-bg: #21AE8C;
    --navbar-icon-bg: #0D2B47;
  }

  // dependable variables
  .sing-dashboard {
    --badge-item-color: var(--badge-item);
    --important-notification: var(--important-notification-color);
    --update-notification: var(--update-notification-color);
    --sidebar-item-active-color: var(--sidebar-item-active);
    --chat-sidebar-bg-color: var(--sidebar-bg-color);
    --sidebar-progress-bg-color: var(--sidebar-action-bg);
    --sidebar-item-hover-bg-color: var(--sidebar-action-bg);
    --widget-shadow: 0 23px 20px -20px rgba(var(--widget-shadow-color), 0.1), 0 0 15px rgba(var(--widget-shadow-color), 0.06);
  }
}