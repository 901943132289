.react-bootstrap-table-pagination {
  ul {
    margin-top: 14px;
    float: right;
    li.page-item,
    li.disabled {
      border: 1px solid #dee2e6;
      border-right: none;
      &.active {
        background-color: $blue;
        a.page-link {
          color: #ffffff;
          font-weight: bold;
        }
      }
      &:hover:not(.active) {
        background-color: #dee2e6;
      }
      a.page-link {
        background-color: transparent;
        border-radius: 0;
        outline: none!important;
        user-select: none;
        box-shadow: none!important;
      }
      &:last-child {
        border-right: 1px solid #dee2e6;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }
}
.react-bootstrap-table-pagination-list-hidden {
  display: none;
}

ul.custom-react-pagination {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    margin-top: 14px;
    float: right;
    li,
    li.disabled {
      border: 1px solid #dee2e6;
      border-right: none;
      &.active {
        background-color: $blue;
        a {
          color: #ffffff;
          font-weight: bold;
        }
      }
      &:hover:not(.active) {
        background-color: #dee2e6;
      }
      a {
        line-height: 1.25;
        font-weight: 400;
        font-size: 1rem;
        color: #495057;
        font-family: "Montserrat", sans-serif;
        display: inline-block;
        margin: 0 2px;
        padding: 0.5rem 0.75rem;
        background-color: transparent;
        border-radius: 0;
        outline: none!important;
        user-select: none;
        box-shadow: none!important;
      }
      &:last-child {
        border-right: 1px solid #dee2e6;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }