@import '../../styles/app';

ul.pagination {
    li {
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        a {
            outline: none !important;
        }
    }
    li.active {
        // background-color: #1A86D0;
        font-weight: bold;
        a {
            text-decoration: none;
            border: none;
            outline: none !important;
        }
    }
}
.ticketsBlock {
    position: relative;
    .listBlock {
        display: inline-block;
        width: 100%;
        padding-right: 10px;
        &.ticketSelected {
            display: none;
        }
    }
    .detailsBlock {
        vertical-align: top;
        position: sticky;
        top: 0;
        display: none;
        width: 100%;
        &.ticketSelected {
            display: inline-block;
        }
        .detailsBackArrow {
            display: block;
            margin-bottom: 8px;
            font-size: 24px;
            cursor: pointer;
        }
    }
}

// @media (min-width: 992px) {
//   .ticketsBlock {
//     .listBlock {
//       width: 50%;
//       &.ticketSelected {
//         display: inline-block;
//       }
//     }
//     .detailsBlock {
//       width: 50%;
//       &.ticketSelected {
//         display: inline-block;
//       }
//       .detailsBackArrow {
//         display: none;
//       }
//     }
//   }
// }

.ticketItem {
    cursor: pointer;

    &:hover {
        background-color: #eeeeee9c;
    }

    &:not(:first-child) {
        border-bottom: 2px dashed #c1c1c1;
    }
    &.selected {
        background-color: #eeeeee80;
    }
}
.agentBadge {
    background-color: #1a86d0;
    color: #fff;
    border-radius: 3px;
    margin-right: 8px;
    padding: 2px 8px;
    margin-top: 8px;
    display: inline-block;
}
.addFileBtn {
    color: #1a86d0;
    font-weight: bold;
    cursor: pointer;
}
.fileRow {
    i {
        color: #ff0000;
        cursor: pointer;
        margin-left: 5px;
    }
}
.labels {
    padding-top: 5px;
    padding-bottom: 5px;
    i {
        cursor: pointer;
    }
    span {
        font-size: 12px !important;
    }
}
.addBtn {
    z-index: 0;
}
.wideFilter {
    height: 38px;
}

.noTickets {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

:global {
    .ticket-section-inner {
        box-shadow: var(--widget-shadow);
        border-radius: $border-radius;
        background: $white;
        padding: $chat-component-padding;
        margin-bottom: $spacer;
        min-width: $chat-section-width;
    }
    .ticket-section {
        width: $chat-section-width;
        // min-width: $chat-section-width;
        height: 100%;
        display: flex;
        flex-direction: column;

        transition: width 0.2s ease-in-out, left 0.2s ease-in-out;
    }
    .ticketPage {
        flex-grow: 1;
        display: flex;
        // height: calc(100vh - #{$navbar-height} - #{$content-padding * 2});
        position: relative;

        .ticketDetails {
            height: 100%;
            flex: 1;
            position: relative;
            width: 100%;
            overflow: hidden;
            left: 0;
        }
        .ticketAside {
            height: 100%;
            width: 0;
            overflow: hidden;
            left: 100vw;

            .backBtn {
              display: none;
            }
        }

        &.withSidebar {
            .ticketDetails {
                width: calc(100% - #{$chat-section-width});
                padding-right: $chat-component-padding;

                .statusField {
                    display: none;
                }
            }

            .ticketAside {
                width: $chat-section-width;
                left: calc(100% - #{$chat-section-width});
            }
        }

        @include media-breakpoint-down(md) { 
          &.withSidebar {
            .ticketDetails {
                padding-right: 0;
                left: -$chat-section-width;
            }

            .ticketAside {
                width: 100%;
                left: 0;

                .backBtn {
                  display: block;
                }
            }
        }
        }
    }
}
