@use "sass:math";

@import '../../../../styles/app';

:global {
    .rc-tabs-dropdown-menu {
        max-height: 200px;
        margin: 0;
        padding: 4px 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        list-style-type: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 2px;
        outline: none;
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    }

    .rc-tabs-dropdown {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: #000000d9;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        position: absolute;
        border: none;
    }
}
.chatInfoTabs {
    :global {
        .rc-tabs {
            border: none !important;
        }
        .rc-tabs-tab {
            padding: 5px 10px;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 1.4em;
        }
        .rc-tabs-tab .rc-tabs-tab-btn {
            font-weight: normal;
        }
        .rc-tabs-tab.rc-tabs-tab-active {
            color: #00a6f8;
        }
        .rc-tabs-ink-bar {
            background: #59c7fd !important;
        }
        .rc-tabs-content-holder {
            padding: 10px;
            overflow-y: auto;

            &::-webkit-scrollbar {
                height: 4px;
                width: 4px;
            }

            &::-webkit-scrollbar:hover {
                background-color: #e9e9e9;
                border: none;
            }

            &::-webkit-scrollbar-thumb {
                border: none;
                background-color: rgba(0, 0, 0, 0.2);

                @include box-shadow(inset 1px 1px 0 rgba(0, 0, 0, 0.1));
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: $gray-600;
            }
        }

        .rc-tabs-nav-measure-ping-left::before,
        .rc-tabs-nav-wrap-ping-left::before,
        .rc-tabs-nav-measure-ping-right::after,
        .rc-tabs-nav-wrap-ping-right::after {
            position: absolute;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.3s;
            content: '';
            pointer-events: none;
            width: 30px;
        }

        .rc-tabs-nav-measure-ping-right::after,
        .rc-tabs-nav-wrap-ping-right::after {
            right: 0;
            box-shadow: inset -10px 0 8px -8px #00000014;
            border-right: 1px solid #ededed;
            opacity: 1;
        }

        .rc-tabs-nav-measure-ping-left::before,
        .rc-tabs-nav-wrap-ping-left::before {
            left: 0;
            box-shadow: inset 10px 0 8px -8px #00000014;
            border-left: 1px solid #ededed;
            opacity: 1;
        }

        .rc-tabs-nav-more {
            border: none;
            background: none;

            position: relative;
            padding: 8px 16px;
            background: 0 0;
            border: 0;
        }
    }
}

.chatInfoHeader {
    color: $white;
    position: relative;
    .avatarsRow {
        display: flex;
        align-items: center;
        padding-right: 10px;
        margin-bottom: 0;

        li {
            width: 15px;
            display: flex;
            justify-content: center;
        }
    }
    .socialLinks {
        display: flex;
        align-items: center;
        margin-bottom: 0;

        .socialLink {
            a {
                background: $white;
                border-radius: 50%;
                color: $purple;
                font-size: 18px;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;

                .fa-facebook {
                    margin-top: 7px;
                    margin-left: 3px;
                    font-size: 22px;
                }
            }
        }

        .socialLink + .socialLink {
            margin-left: math.div($spacer, 1.2);
        }
    }
}

.dynamicCard {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    @include chat-scrollbar(rgba($text-muted, 0.3), 3px);
    .toggleHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    :global {
        .accordion {
            .card {
                position: static;
                .card-body {
                    padding: 0.35em 0.35em 0.35em 2em;
                }
                .card-body.files-tab {
                    padding: 0.35em 1.3em;
                }
                border-left: none;
                border-top: none;
                border-right: none;
                box-shadow: none;
                &:last-child {
                    border-bottom: none;
                }
                button:not(.editBtn) {
                    text-align: left;
                    border: none;
                    font-size: 15px;
                    padding: 13px 1.25rem;
                    background: #fff;
                    &:focus {
                        outline: none;
                    }
                    .la.la-angle-up {
                        transition: transform 0.25s ease;
                    }
                    &.active {
                        .la.la-angle-up {
                            transform: rotate(180deg);
                        }
                    }
                    img {
                        margin-right: 10px;
                        width: 20px;
                    }
                }
            }
        }
        .toggle {
            input[type='checkbox'] {
                display: none;
            }
            label {
                color: #000000;
                font-size: 19px;
                width: 100%;
                padding: 13px 1.25rem;
                margin: 0;
                position: relative;
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            input[type='checkbox'] + label::before {
                content: ' ';
                display: block;
                height: 12px;
                width: 35px;
                border-radius: 9px;
                position: absolute;
                top: 20px;
                right: 10px;
                background: $teal;
            }

            input[type='checkbox'] + label::after {
                content: ' ';
                display: block;
                height: 20px;
                width: 20px;
                border-radius: 50%;
                position: absolute;
                top: 16px;
                border: 1px solid #e6e5ea;
                right: 25px;
                background: #fff;
                transition: all 0.3s ease-in;
            }

            input[type='checkbox']:checked + label::after {
                right: 5px;
                transition: all 0.3s ease-in;
            }
        }
    }
    .linksBody {
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 5px;
        }
    }
    .personalInformation {
        padding: 0;
        margin: 0;
        overflow-wrap: anywhere;
        
        li {
            margin-bottom: 8px;
        }
        .label {
            color: #313947;
            font-size: 14px;
            margin-bottom: 0;
        }
        // li:nth-child(odd) {
        //   color: #313947;
        //   font-size: 17px;
        // }
        // li:nth-child(even) {
        //   color: rgba(#313947,.5);
        //   font-size: 14px;
        //   margin-top: 5px;
        // }
        // li:nth-child(3n+2) {
        //   margin-bottom: 20px;
        // }
    }
    .listWithImages {
        margin: 0;
        padding: 0;
        li {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            img {
                height: 20px;
                width: 20px;
                object-fit: cover;
            }
            .imgText {
                margin-left: 15px;
            }
        }
    }
}
.cursorStyle {
    cursor: pointer;
}
.groupList {
    position: relative;
    z-index: 999;
}
.groupListModalWrapper {
    position: absolute;
    top: 50%;
    left: -50%;
    z-index: 1000;

    .groupListModal {
        overflow-y: auto;
        max-height: 450px;

        @include chat-scrollbar(rgba($text-muted, 0.3), 3px);

        width: 300px;
        color: $text-color;

        .groupListHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: $spacer;
            span {
                cursor: pointer;
            }
            .laLg {
                vertical-align: middle;
            }
        }

        .groupList {
            margin: 0;
            padding: 0;

            & > li {
                display: flex;
                align-items: center;
                border-radius: $border-radius;
                padding: $spacer * 0.5;

                &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.01);
                }
            }
        }
    }

    @include media-breakpoint-down(md) {
        width: 100%;
        left: 0;

        .groupListModal {
            margin: 0;
            width: 100%;
        }
    }

    .backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($modal-backdrop-bg, $modal-backdrop-opacity);
        z-index: -1;
    }
}

.pearlBg {
    background-color: #7f53ac !important;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%) !important;
}

.switch {
    i {
        height: 12px;
        padding-right: 15px;
    }

    i::before {
        display: block;
        width: 16px;
        height: 16px;
        margin-top: -2px;
        box-shadow: none;
        border: 1px solid $border-color;
    }

    :checked + i {
        padding-right: 0;
        padding-left: 15px;
        background: darken($success, 15%);
    }
}
.guestLinkModal {
    width: 320px!important;
}
.guestLink {
    word-break: break-all;
}
.guestLinkCopy {
    cursor: pointer;
    font-size: 20px;
}