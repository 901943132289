@import '../../../../styles/app';

.chatsSectionWrap {
    flex: 1;
    overflow: auto;
    z-index: 0 !important;

    @include chat-scrollbar(rgba($text-muted, 0.15), 3px);
}

.avatarWrap {
    position: relative;
}

.cirecleIcon {
    background-color: #1a86d0;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #f7f7f8;
    font-size: 21px;
    position: absolute;
    bottom: -12px;
    left: -12px;
    z-index: 1;
    font-size: 18px;
    
    &.fb {
        background-color: #3b5998;
        font-size: 21px;
    }
    &.instagram {
        background-color: #405de6;
        font-size: 21px;
    }
    &.telegram {
        background-color: #0088cc;
        font-size: 18px;
    }
    &.viber {
        background-color: #665cac;
        font-size: 21px;
    }
    
}

.chatTabs {
    :global {
        .rc-tabs {
            border: none !important;
            justify-content: space-between;
        }
        .rc-tabs-tab {
            padding: 5px 20px;
            text-transform: capitalize;
            font-size: 14px;
            line-height: 1.4em;
            flex: 1;
        }
        .rc-tabs-tab .rc-tabs-tab-btn {
            font-weight: normal;
        }
        .rc-tabs-tab.rc-tabs-tab-active {
            color: #00a6f8;
        }
        .rc-tabs-ink-bar {
            background: #59c7fd !important;
        }

        .rc-tabs-tabpane {
            padding-left: $chat-component-padding;
            padding-right: $chat-component-padding;
        }
    }
}

.chatList {
    margin: 0 (-$chat-component-padding);
}

.chatListItem {
    padding-left: $chat-component-padding;

    .chatListItemWrapper {
        display: flex;
        align-items: center;
        padding: ($spacer * 1.5) $chat-component-padding ($spacer * 1.5) 0;

        position: relative;
    }

    .chatItemMain {
        flex: 1;
        min-width: 0;
    }

    .chatTitle {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        span {
            margin: 0 5px;
        }
        .groupChatIcon {
            width: 15px;
        }
        i {
            opacity: 0.3;
        }
    }

    .chatLastMessage {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .chatLastMessage,
    .timestamp {
        color: $text-muted;
    }

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.01);
    }

    &.active {
        background: lighten($purple, 15%);
    }

    &.active {
        .chatTitle,
        .chatLastMessage,
        .timestamp {
            color: $white;
        }
    }

    &.active {
        .chatTitle {
            font-weight: $font-weight-semi-bold;
        }
    }
}

.chatListItem + .chatListItem {
    .chatListItemWrapper {
        border-top: 1px solid $border-color;
    }

    &.active .chatListItemWrapper {
        border-top-color: lighten($info, 15%);
    }
}

.personalChats,
.groupChats {
    flex: 1;
    overflow: auto;
    @include chat-scrollbar(rgba($text-muted, 0.15), 3px);
}

.allChats {
    overflow: auto;
    flex: 1;
    @include chat-scrollbar(rgba($text-muted, 0.15), 0);
}

.chatList {
    // margin: 0 (-$chat-component-padding);
}

.chatListItem {
    padding-left: $chat-component-padding;

    .chatListItemWrapper {
        display: flex;
        align-items: center;
        padding: 1rem $chat-component-padding 1rem 0;

        .avatarsColumn {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;

            li {
                height: 15px;
                display: flex;
                align-items: center;
            }
        }
    }

    .chatItemMain {
        flex: 1;
        min-width: 0;
    }

    .chatTitle {
        margin-bottom: 0;
    }

    .chatLastMessage {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .chatLastMessage,
    .timestamp {
        color: $text-muted;
    }

    &:hover {
        cursor: pointer;
        background: rgba(0, 0, 0, 0.01);
    }

    &.active {
        background: lighten($purple, 15%);
    }

    &.active {
        .chatTitle,
        .chatLastMessage,
        .timestamp {
            color: $white;
        }
    }

    &.active {
        .chatTitle {
            font-weight: $font-weight-semi-bold;
        }
    }
}

.chatListItem + .chatListItem {
    .chatListItemWrapper {
        border-top: 1px solid $border-color;
    }

    &.active .chatListItemWrapper {
        border-top-color: lighten($info, 15%);
    }
}

.searchBox {
    .chatInput {
        background-color: #f7f7f8;
        &:focus {
            background-color: #f7f7f8;
        }
    }

    :global {
        .input-group-prepend {
            background-color: #f7f7f8;
            transition: background-color ease-in-out 0.15s;
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
        }
    }
}

.timestamp {
    width: 100%;
    text-align: right;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;

    &.live {
        background-color: green;
    }
}
