@import "../../styles/app";

.root {
  width: 100%;
  height: calc(100vh - #{$navbar-height} - #{$content-padding * 2});
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    font-size: 32px;
    margin: 0 $spacer;
  }
  
  .logo {
    width: 100px;
  }
}