@import '../../styles/app';

.regPage {
  height: auto;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.regWidget {
  max-width: 980px;
}
.colorInput {
  position: relative;
  .colorBox {
    height: 30px;
    width: 30px;
    display: inline-block;
    cursor: pointer;
  }
}
.wizardControlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.wizardControl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  background-color: #eeeeee;
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px;
}
.wizardControl.active {
  background-color: #1A86D0;
  color: #fff;
}
.wizardDivider {
  height: 2px;
  width: 20px;
  background-color: #eeeeee;
}
.authLogo {
  position: absolute;
  top: 36px;
  left: 45px;
  z-index: 10;
  img {
    max-width: 30vw;
    display: none;
  }
}

.sides {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;

  
  
  @media (min-width: 426px) {
    flex-direction: row;

    .leftSide {
      width: 48%;
    }
    .rightSide {
      width: 48%;
    }
  }

}

@media(min-width: 400px) {
  .authLogo {
    top: 20px;
    left: 24px;
    img {
      max-width: 60px;
      display: block;
    }
  }
}

@media(min-width: 768px) {
  .authLogo {
    top: 16px;
    left: 20px;
    img {
      max-width: 80px;
      display: block;
    }
  }
}

@media(max-height: 600px) {
  .authLogo {
    img {
      display: none;
    }
  }
  .regPage {
    flex-direction: column;
  }
}

.subscriptionCost {
  font-size: 2rem;

  .currency {
    font-size: 1rem;
  }
}

.trialInfo {
  font-size: 2rem;
  
  
}

.additional {
  font-size: 1.2rem;
  font-weight: bold;
  color: $blue;
}

.accept {
  font-size: $font-size-sm;
}