.notificationContainer {
  position: sticky;
  top: 0;
  z-index: 1000;
  .notification {
    z-index: 1;
    margin-bottom: 0;
    border-radius: 0;
  }
  .notificationImage {
    height: 28px;
    width: 28px;
  }
}