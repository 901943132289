@import '../../../../styles/app';

.elementsSectionWrap {
  flex: 1;
  overflow: auto;
  z-index: 0 !important;

  @include chat-scrollbar(rgba($text-muted, 0.15), 3px);
}

.actionsWrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .quickReply {
    flex: 1;
  }

  .quickReplyItem {
    border: 1px solid #efefef;
    padding: 3px 6px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    margin-bottom: 6px;
    border-radius: 4px;
  }

  .quicksHolder {
    // overflow-y: scroll;
    margin-top: $widget-padding-vertical;
  }

  .pillsHolder {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin-top: $widget-padding-vertical;
    :global {
      .badge {
        font-size: 13px;
        margin: 0 3px;
        margin-bottom: 6px;        
      }
    }
  }
  .dynamicCard {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    @include chat-scrollbar(rgba($text-muted, 0.3), 3px);
  }
}
