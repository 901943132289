input.dateRangeInput {
  // display: block;
  width: 100%;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c1ccd3;
  border-radius: 0.3rem;
  &:focus {
    border: 1px solid #0e7fe1;
    outline: none;
  }
}
.DayPicker-Day:hover {
  border-radius: 0px;
}