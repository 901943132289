.helpText {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.3;
}
.helpIcon {
  cursor: pointer;
  font-size: larger;
  align-items: center;
  display: flex;
}
.helperHolder {
  display: inline-block;
}