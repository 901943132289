@import '../../styles/app';



.statsTable {
  min-width: 640px;
  td {
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.datePickerWrapper {
  width: 285px;
  .clearDateICon {
    cursor: pointer;
    width: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;
    &:hover {
      background-color: $gray-400;
    }
  }
}
