/**
 * Customized version of bootstrap using variables from _variables.scss.
 * This file is loaded via separate loader thus allowing to use original bootstrap classes (e.g. .btn-default) through out the app.
 */
$ignore-warning: true;
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,800');
@import '../../node_modules/bootstrap/scss/functions';
@import 'variables';
@import 'theme-variables';
@import '../../node_modules/bootstrap/scss/bootstrap';
// @import 'node_modules/glyphicons-halflings/scss/glyphicons-halflings';
@import './font-awesome/font-awesome';
@import '../../node_modules/line-awesome/dist/line-awesome/css/line-awesome.css';
@import '../fonts/flaticon/flaticon';
@import '../../node_modules/animate.css/animate';
@import '../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';
@import '../../node_modules/react-toastify/dist/ReactToastify.css';

@import 'mixins';
@import 'base';
@import 'auth';
@import 'overrides';
@import 'general';
@import 'utils';
@import 'pagination';
@import 'date-range-picker';
@import 'popover';
@import 'quill';
@import 'select';