@import '../../../../styles/app';

.chatDialogSection {
    min-width: 50%;
    padding: 0 $chat-component-padding;
}

.noActiveChats {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.chatDialogHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer;

    .infoIcon {
        font-size: $spacer * 2;
        color: $text-muted;
    }
}

.chatDialogBody {
    flex: 1;
    overflow: auto;
    @include chat-scrollbar(rgba($text-muted, 0.3), 3px);

    flex-direction: column-reverse;
    display: flex;

    .dialogDivider {
        text-align: center;
        padding: $spacer 0;
        font-size: $font-size-larger;
        color: $text-muted;
    }
}

.newMessageBtn {  
  @include media-breakpoint-down(md) {
    flex: 1;
    width: 100%;
    min-width: 117px;
  }
}

.newMessagesBtn {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 140px;
}

.newMessage {
    // display: flex;
    // align-items: center;

    textarea {
        min-width: 100px;
        border: 1px solid #ededed;
        resize: none;

        &.mobile {
            font-size: 16px;
        }
    }

    input {
        margin: 0 $spacer;
    }

    .attachment {
        font-size: 16px;
        color: $text-muted;
        background-color: transparent;
        border: none;
        &:hover {
            color: darken($text-muted, 10%);
        }
    }

    .replyTemplate {
        font-size: 16px;
        color: $text-muted;
        background-color: transparent;
        border: none;
        margin-right: 5px;
        &:hover {
            color: darken($text-muted, 10%);
        }
    }
}

.attachmentsBlock {
    border-top: 1px solid rgba($text-muted, 0.3);
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    margin-top: 15px;
    i {
        font-size: 24px;
        padding-right: 1rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
    }
    .attachmentsList {
        flex: 1;
        .attachedFile {
            &:not(:last-child) {
                border-bottom: 1px solid rgba($text-muted, 0.2);
            }
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 4px;
            padding-top: 4px;
            padding-right: 0.8rem;
            padding-left: 0.8rem;
            align-items: center;
            .rmButton {
                font-size: 20px;
                padding: 0px;
                color: $text-muted;
                background-color: transparent;
                border: none;
                height: 25px;
                &:hover {
                    color: darken($text-muted, 10%);
                }
                i {
                    padding: 0px;
                }
            }
        }
    }
}
.typing {
    margin-left: 60px;
    background-color: $gray-400;
    color: #ffffff;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    display: inline-block;
}
.replyRow {
    border-top: 1px solid #ccc;
    margin: 0;
}
.replyTable {
    .active.page-item {
        background-color: $blue;
    }
    thead {
        th {
            visibility: hidden;
            padding: 0;
            font-size: 1px;
        }
    }
    td {
        cursor: pointer;
        white-space: pre-wrap;
        word-break: break-word;
        &.titleColumn {
            max-width: 80px;
            width: 80px;
        }
    }
}
.dropZoneWrapper {
    position: relative;
    outline: none;
}
.dropZone {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    color: #555;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ContextMenu {
    font-size: 0.9rem;
}

.closeBtn {
    display: inline-block !important;
    @include media-breakpoint-down(lg) {
        display: none !important;
    }
}