.comment {
    padding: 4px 10px;
    margin: 4px 0;
    background-color: hsl(0, 0%, 97%);

    .text {
        padding: 5px 0px;
    }
    .authorTime {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.8rem;;
        
        .author {
            flex: 1;
        }
        .time {
            flex: 1;
            text-align: right;
        }
    }
}