@import '../../styles/app';

.historyTable {
  td {
    white-space: pre-wrap;
    word-break: break-word;
  }
}
.faqText {
  p {
    margin-bottom: 2px;
  }
}