.page-title {
    margin: 0 0 calc(1rem + 6px);
}

.page-top-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & .close {
        margin-left: 25px;
    }
}

.input-group.focus {
    .input-group-addon,
    input {
        background-color: #f1f1f1;
    }
}

.animated {
    animation-duration: 0.5s;
}

.chat-notification-sing {
    position: absolute;
    top: 20px;
    left: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: theme-color('danger');
}

#notification-buttons {
    display: flex;

    button {
        flex: 1;
        font-family: $font-family-base;
        font-size: 0.875rem;
        background-color: #f8f8f8;

        &.active {
            color: #343434;
            background-color: #dfdfdf;
            background-image: none;
            border-color: #adadad;
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.12);
        }
    }
}

.btn-toolbar .btn {
    margin-left: 0.5rem;
}

/* --------- Forms --------- */
.form-action {
    background-color: #f5f5f5;
    margin: $spacer 0 0;
    padding: $spacer * 0.5 $spacer;
}

.widget-top-overflow,
.widget-middle-overflow {
    position: relative;
    margin: 0 (-$widget-padding-horizontal);

    > img {
        max-width: 100%;
    }
}

.widget-top-overflow {
    margin-top: (-$widget-padding-vertical);
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    overflow: hidden;

    > img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    > .btn {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        margin-right: $widget-padding-horizontal;

        @include media-breakpoint-up(md) {
            top: auto;
            bottom: 0;
        }
    }
}

.widget-table-overflow {
    margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);

    th:first-child,
    td:first-child {
        padding-left: $widget-padding-horizontal;
    }

    th:last-child,
    td:last-child {
        padding-right: $widget-padding-horizontal;
    }
}

.table-bordered th {
    background-color: $gray-300;
}

table > thead > tr > th {
    border-top: none !important;
    border-bottom-width: 1px !important;
    // text-transform: uppercase;
}

/* --------- Pagination --------- */
.pagination {
    font-weight: $font-weight-normal;

    > .page-item {
        display: inline; // Remove list-style and block-level defaults

        > .page-link,
        > span {
            border-radius: $border-radius;
            margin: 0 2px;
        }

        > .page-link {
            text-decoration: none;
            border: none;
        }
    }
}

/* --------- Stats Row --------- */
.stats-row {
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
}

.stat-item {
    display: inline-block;
    padding-right: 1.25rem;

    & + .stat-item {
        padding-left: 1.25rem;
        border-left: 1px solid $hr-border-color;
    }

    .name {
        margin-bottom: 0.25rem;
        font-size: $font-size-sm;
    }

    &.stat-item-mini-chart {
        position: relative;
        top: -1rem;
        padding-left: 0;
        border-left: none;
    }
}

/*         Widgets Grid          */

.widget-container {
    min-height: 30px;

    .widget:not(.fullscreened) > header {
        cursor: move;
    }
}

.widget-placeholder {
    background: rgba($white, 0.4);
    border: 1px dashed $gray-600;
    margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px;
}

.sortable-chosen {
    opacity: 1 !important;
}

.widget-placeholder-react {
    background: rgba($white, 0.4) !important;
    border: 1px dashed $gray-600 !important;
    margin: -1px -1px calc(#{$grid-gutter-width} - 1px) -1px !important;
    > * {
        opacity: 0;
    }
}

/*         Post Links           */

.post-links {
    margin-bottom: 0;
    font-size: $font-size-sm;
    padding-left: 0;

    @include clearfix();

    > li {
        float: left;
        list-style: none;

        + li {
            &::before {
                color: #999;
                content: '\25cf';
                padding: 0 8px;
            }
        }

        > a {
            text-decoration: none;
            color: $text-muted;

            &:hover {
                color: $text-muted;
            }
        }
    }

    &.no-separator > li + li {
        margin-left: 12px;

        &::before {
            content: normal;
        }
    }
}

/*          Post Comments           */

.post-comments {
    margin-bottom: $spacer * 0.5;
    margin-left: -20px;
    margin-right: -20px;
    font-size: $font-size-sm;

    @include clearfix();

    > li {
        padding: 10px;
        border-top: 1px solid #e7e7e7;
        list-style: none;

        @include clearfix();
    }

    p:last-child {
        margin-bottom: 0;
    }

    .avatar {
        margin-top: 1px;
    }

    .author {
        margin-top: 0;
        margin-bottom: 2px;
        color: #7ca9dd;
    }

    .comment-body {
        overflow: auto;
    }

    h6.author > small {
        font-size: 11px;
    }

    .widget > footer & {
        margin-left: -$widget-padding-horizontal;
        margin-right: -$widget-padding-horizontal;
    }
}

.btn-link {
    background: transparent;
    border: 0;
    outline: 0;
    color: $link-color;
    padding: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}

.rc-tabs-dropdown-menu {
    max-height: 200px;
    margin: 0;
    padding: 4px 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
}

.rc-tabs-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
}

.rc-tabs-dropdown-menu-item {
    cursor: pointer;
}

.short-string {
    display: inline-block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.version-feedback {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    background-color: var(--sidebar-bg-color);
}

.short-string-inline {
    display: inline-block;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.meta {
    display: flex;
    flex-direction: column;
    .short-string-inline {
        text-align: center;
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(md) {
        flex-direction: row;

        .short-string-inline {
            text-align: left;
            margin-bottom: initial;
        }
    }
}

.oneliner {
    white-space: nowrap;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}